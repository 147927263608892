/* PostDetail.css */
.recipe-loader,
.recipe-error {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  text-align: center;
}

/* Estilos para recetas */
.recipe-detail .hero-section {
  position: relative;
  height: 60vh;
}

.recipe-detail .hero-image {
  height: 100%;
  background-size: cover;
  background-position: center;
  position: relative;
}

.recipe-detail .hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: flex-end;
  padding-bottom: 2rem;
}

.recipe-detail .hero-content {
  color: white;
}

.recipe-detail .categories {
  margin-bottom: 1rem;
}

.category-tag {
  background: rgba(255, 255, 255, 0.2);
  padding: 0.3rem 1rem;
  border-radius: 20px;
  margin-right: 1rem;
  font-size: 0.9rem;
}

.recipe-container {
  padding: 2rem 0;
}

.recipe-intro {
  margin-bottom: 3rem;
}

.recipe-description {
  font-size: 1.2rem;
  line-height: 1.8;
  color: #4a4a4a;
  margin-bottom: 2rem;
}

.recipe-meta {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
}

.meta-item {
  text-align: center;
  flex: 1;
  min-width: 120px;
}

.meta-icon {
  font-size: 1.5rem;
  color: #ff6b00;
  margin-bottom: 0.5rem;
}

.meta-label {
  display: block;
  font-size: 0.9rem;
  color: #666;
}

.meta-value {
  font-size: 1.1rem;
  font-weight: bold;
  color: #333;
}

.rating-stars {
  color: #ffd700;
  font-size: 1.2rem;
}

.star.empty {
  color: #ddd;
}

.ingredients-section,
.steps-section {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.ingredients-list {
  list-style: none;
  padding: 0;
}

.ingredient-item {
  padding: 0.5rem 0;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
}

.ingredient-amount {
  color: #666;
  min-width: 100px;
}

.step-item {
  margin-bottom: 2rem;
}

.step-number {
  font-weight: bold;
  color: #ff6b00;
  margin-bottom: 0.5rem;
}

.step-description {
  line-height: 1.8;
}

.step-image-container {
  margin-top: 1rem;
}

.step-image {
  max-width: 100%;
  border-radius: 8px;
}

/* Estilos para consejos */
.tip-detail .post-hero {
  height: 50vh;
  background-size: cover;
  background-position: center;
  position: relative;
}

.tip-detail .post-hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
}

.post-title {
  color: white;
  font-size: 3rem;
  margin-bottom: 1rem;
}

.post-meta-info {
  color: white;
  display: flex;
  gap: 2rem;
  font-size: 1.1rem;
}

.post-meta-info span {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.post-content-container {
  margin-top: -5rem;
  position: relative;
  z-index: 2;
}

.post-content {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.post-text {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #333;
  white-space: pre-line;
}

@media (max-width: 768px) {
  .recipe-meta {
    flex-direction: column;
  }

  .meta-item {
    width: 100%;
    margin-bottom: 1rem;
  }

  .post-title {
    font-size: 2rem;
  }

  .post-meta-info {
    flex-direction: column;
    gap: 1rem;
  }
}