.tip-form {
    padding: 20px;
  }
  
  .editor-container {
    height: 400px;
    margin-bottom: 20px;
  }
  
  .ql-editor {
    min-height: 300px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
  }
  
  /* Estilos para el contenedor del editor */
  .quill {
    background: white;
    border-radius: 4px;
    border: 1px solid #ddd;
  }
  
  /* Estilos para la barra de herramientas */
  .ql-toolbar {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom: 1px solid #ddd;
    background: #f8f9fa;
  }
  
  /* Estilos para el área de edición */
  .ql-container {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }