.bbq-header {
  background-color: #fff8ef;
  padding: 1rem 0;
  border-bottom: 1px solid #ffddb3;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.bbq-title {
  font-size: 2rem;
  margin: 0;
  color: #ff6b00;
  font-weight: bold;
  text-decoration: none;
}

.bbq-nav {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: flex-end;
  align-items: center;
  gap: 2rem;
}

.bbq-nav a {
  color: #4a4a4a;
  text-decoration: none;
  font-weight: 500;
  font-size: 1.1rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.bbq-nav a:hover {
  color: #ff6b00;
  background-color: rgba(255, 107, 0, 0.1);
}

.bbq-nav a.active {
  color: #ff6b00;
  background-color: rgba(255, 107, 0, 0.1);
}

.admin-link {
  background-color: #34495e !important;
  color: white !important;
}

.admin-link:hover {
  background-color: #2c3e50 !important;
  color: white !important;
}

/* Estilos para el header administrativo */
.bbq-header.admin-header {
  background-color: #2c3e50;
  color: white;
}

.bbq-header.admin-header .bbq-title {
  color: white;
}

.bbq-header.admin-header .bbq-nav a {
  color: #ecf0f1;
}

.bbq-header.admin-header .bbq-nav a:hover,
.bbq-header.admin-header .bbq-nav a.active {
  color: #3498db;
  background-color: rgba(52, 152, 219, 0.1);
}

.public-link {
  background-color: #27ae60 !important;
  color: white !important;
}

.public-link:hover {
  background-color: #219a52 !important;
}