.bbq-footer {
    background-color: #2c3e50;
    color: #ecf0f1;
    padding: 3rem 0 1rem;
    margin-top: 4rem;
  }
  
  .bbq-footer h5 {
    color: #ff6b00;
    margin-bottom: 1.5rem;
    font-weight: bold;
  }
  
  .bbq-footer p {
    color: #bdc3c7;
    line-height: 1.6;
  }
  
  .bbq-footer ul li {
    margin-bottom: 0.5rem;
  }
  
  .bbq-footer ul li a {
    color: #bdc3c7;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .bbq-footer ul li a:hover {
    color: #ff6b00;
  }
  
  .social-icons {
    display: flex;
    gap: 1rem;
  }
  
  .social-icons a {
    color: #bdc3c7;
    font-size: 1.5rem;
    transition: color 0.3s ease;
  }
  
  .social-icons a:hover {
    color: #ff6b00;
  }
  
  .bbq-footer hr {
    margin: 2rem 0;
    border-color: #34495e;
  }
  
  .bbq-footer .text-center {
    color: #95a5a6;
    font-size: 0.9rem;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .bbq-nav {
      flex-direction: column;
      gap: 1rem;
    }
  
    .bbq-title {
      font-size: 1.5rem;
      text-align: center;
    }
  
    .bbq-footer {
      text-align: center;
    }
  
    .social-icons {
      justify-content: center;
      margin-top: 1rem;
    }
  }