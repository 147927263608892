.blog-hero {
    position: relative;
    height: 40vh;
    background-image: url('/public/bbq-pattern.webp');
    background-size: cover;
    background-position: center;
    color: white;
  }
  
  .blog-hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
  }
  
  .blog-content {
    padding: 4rem 0;
  }
  
  .post-card {
    transition: transform 0.3s ease;
    height: 100%;
  }
  
  .post-card:hover {
    transform: translateY(-5px);
  }
  
  .post-image {
    height: 200px;
    object-fit: cover;
  }
  
  .post-placeholder-image {
    height: 200px;
    background-color: #f8f9fa;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    color: #dee2e6;
  }
  
  .post-meta {
    display: flex;
    justify-content: space-between;
    font-size: 0.9rem;
    color: #6c757d;
    margin-top: 1rem;
  }
  
  .post-link {
    text-decoration: none;
    color: inherit;
  }
  
  .newsletter-section {
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 3rem;
  }