/* Colores del tema */
:root {
    --color-orange: #FF9800;
    --color-beige: #F5DEB3;
    --color-carbon: #333333;
    --color-white: #FFFFFF;
  }
  
  body {
    background-color: var(--color-beige);
    color: var(--color-carbon);
  }
  
  .admin-dashboard, .recipe-list, .tip-list, .recipe-form, .tip-form {
    background-color: var(--color-white);
    border-radius: 8px;
    padding: 20px;
    margin-top: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  h1, h2 {
    color: var(--color-orange);
  }
  
  .btn-primary {
    background-color: var(--color-orange);
    border-color: var(--color-orange);
  }
  
  .btn-primary:hover {
    background-color: #E68A00;
    border-color: #E68A00;
  }
  
  .table {
    background-color: var(--color-white);
  }
  
  .table thead {
    background-color: var(--color-carbon);
    color: var(--color-white);
  }
  
  .form-control:focus {
    border-color: var(--color-orange);
    box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.25);
  }
  
  /* Estilos adicionales según sea necesario */