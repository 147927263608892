/* Estilos generales */
.history-hero {
    position: relative;
    height: 80vh;
    background-image: linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.6)), url('/public/bbq-hero.webp');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    color: white;
  }
  
img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }

  .history-hero-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }
  
  .hero-content {
    position: relative;
    z-index: 2;
  }
  
  .hero-content h1 {
    font-size: 4.5rem;
    font-weight: 800;
    margin-bottom: 1.5rem;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
  }
  
  .hero-subtitle {
    font-size: 1.8rem;
    font-weight: 300;
    max-width: 600px;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
  }
  
  .scroll-indicator {
    position: absolute;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    animation: bounce 2s infinite;
  }
  
  .scroll-arrow {
    width: 30px;
    height: 30px;
    border-right: 2px solid white;
    border-bottom: 2px solid white;
    transform: rotate(45deg);
    margin: 0 auto;
    margin-top: 1rem;
  }
  
  /* Contenido principal */
  .history-content {
    padding: 4rem 0;
  }
  
  .content-intro {
    text-align: center;
    max-width: 800px;
    margin: 0 auto 4rem;
  }
  
  .section-icon {
    font-size: 2.5rem;
    color: #ff6b00;
    margin-bottom: 1.5rem;
  }
  
  .intro-text {
    font-size: 1.5rem;
    color: #2c3e50;
    line-height: 1.8;
  }
  
  /* Secciones generales */
  .history-section {
    margin-bottom: 6rem;
    position: relative;
  }
  
  .section-header {
    text-align: center;
    margin-bottom: 3rem;
  }
  
  .section-header h2 {
    font-size: 2.8rem;
    color: #2c3e50;
    margin-bottom: 1.5rem;
    position: relative;
    padding-bottom: 1.5rem;
  }
  
  .section-header h2:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 3px;
    background-color: #ff6b00;
  }
  
  /* Estilos para el texto */
  .feature-text {
    font-size: 1.4rem;
    color: #34495e;
    line-height: 1.8;
    margin-bottom: 2rem;
    font-weight: 300;
  }
  
  .history-text p {
    font-size: 1.1rem;
    line-height: 1.8;
    color: #4a4a4a;
    margin-bottom: 1.5rem;
  }
  
  /* Cajas destacadas */
  .highlight-box {
    background: #fff8f5;
    border-left: 4px solid #ff6b00;
    padding: 2rem;
    margin: 2rem 0;
    border-radius: 0 8px 8px 0;
  }
  
  .highlight-box ul {
    list-style: none;
    padding-left: 0;
    margin-top: 1rem;
  }
  
  .highlight-box li {
    position: relative;
    padding-left: 1.5rem;
    margin-bottom: 0.5rem;
  }
  
  .highlight-box li:before {
    content: '•';
    color: #ff6b00;
    position: absolute;
    left: 0;
    font-size: 1.2rem;
  }
  
  /* Imágenes y contenedores de imágenes */
  .history-image-container {
    position: sticky;
    top: 2rem;
  }
  
  .history-image {
    width: 100%;
    height: 400px;
    background-color: #f8f9fa;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 15px rgba(0,0,0,0.1);
    transition: transform 0.3s ease;
  }
  
  .history-image:hover {
    transform: translateY(-5px);
  }
  
  .image-caption {
    text-align: center;
    margin-top: 1rem;
    font-style: italic;
    color: #666;
  }
  
  /* Regiones */
  .regions-intro {
    text-align: center;
    max-width: 900px;
    margin: 0 auto 4rem;
  }
  
  .regions-map-container {
    position: relative;
    margin: 4rem 0;
    padding: 2rem;
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0,0,0,0.05);
  }
  
  .regions-map {
    width: 100%;
    height: 500px;
    background-color: #f8f9fa;
    border-radius: 8px;
    margin-bottom: 2rem;
  }
  
  .map-legend {
    display: flex;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap;
  }
  
  .legend-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .legend-color {
    width: 20px;
    height: 20px;
    border-radius: 4px;
  }
  
  /* Bloques de región */
  .region-block {
    margin-bottom: 6rem;
    padding: 3rem;
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0,0,0,0.08);
  }
  
  .region-header {
    margin-bottom: 3rem;
  }
  
  .region-number {
    font-size: 4rem;
    color: #ff6b00;
    opacity: 0.2;
    font-weight: 800;
    margin-right: 1rem;
  }
  
  .region-header h3 {
    font-size: 2.5rem;
    color: #2c3e50;
    display: flex;
    align-items: center;
  }
  
  .subregion {
    margin-bottom: 3rem;
  }
  
  .subregion h4 {
    color: #ff6b00;
    font-size: 1.4rem;
    margin-bottom: 1rem;
  }
  
  .subregion-content {
    padding-left: 1.5rem;
    border-left: 3px solid #ff6b00;
  }
  
  /* Newsletter section */
  .newsletter-section {
    background: linear-gradient(135deg, #2c3e50, #34495e);
    color: white;
    padding: 4rem 2rem;
    border-radius: 12px;
    margin-top: 6rem;
    text-align: center;
  }
  
  .newsletter-container {
    max-width: 600px;
    margin: 0 auto;
  }
  
  .newsletter-container h3 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }
  
  .newsletter-form {
    display: flex;
    gap: 1rem;
    margin-top: 2rem;
  }
  
  .newsletter-form input {
    flex: 1;
    padding: 1rem;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .btn-subscribe {
    background: #ff6b00;
    color: white;
    border: none;
    padding: 1rem 2rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .btn-subscribe:hover {
    background: #ff5500;
  }
  
  /* Animaciones */
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(-5px);
    }
  }
  
  /* Media queries */
  @media (max-width: 768px) {
    .hero-content h1 {
      font-size: 3rem;
    }
  
    .hero-subtitle {
      font-size: 1.4rem;
    }
  
    .region-block {
      padding: 1.5rem;
    }
  
    .newsletter-form {
      flex-direction: column;
    }
  
    .region-header h3 {
      font-size: 2rem;
    }
  
    .region-number {
      font-size: 3rem;
    }
  }
  
  /* Estilos para las imágenes de región */
  .region-images {
    position: relative;
  }
  
  .main-image {
    width: 100%;
    height: 400px;
    background-color: #f8f9fa;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 1rem;
    box-shadow: 0 4px 15px rgba(0,0,0,0.1);
  }
  
  .secondary-images {
    display: flex;
    gap: 1rem;
  }
  
  .secondary-image {
    flex: 1;
    height: 200px;
    background-color: #f8f9fa;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 15px rgba(0,0,0,0.1);
  }
  
  /* Estilos específicos para cada región */
  .texas-block { border-top: 5px solid #dc3545; }
  .kansas-block { border-top: 5px solid #fd7e14; }
  .carolinas-block { border-top: 5px solid #20c997; }
  .memphis-block { border-top: 5px solid #6f42c1; }